import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Helmet from 'react-helmet';
import { routes } from 'inkp-routes/public';
import InkpConfig from 'inkp-config';

import CantFindProduct from '../../components/CantFindProduct';

// GTM helpers
import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';

export default class PrivacyPolicy extends React.Component<RouteComponentProps> {
  renderContent() {
    return (
      <div className="w-full px-1 ta-left">
        <style jsx={true}>{`
          h2 {
            font-size: 24px;
            font-weight: 700;
          }

          h3 {
            font-size: 20px;
            font-weight: bold;
          }

          h4 {
            font-size: 18px;
            font-weight: bold;
          }

          .fs-18 {
            font-size: 18px;
          }

          ul {
            margin-left: -24px;
          }
        `}</style>
        <p className="mt-2 lg:mt-4 fs-18">
          Welcome to the Inkpop.com website. The following describes the terms under which we provide the Inkpop.com
          website and Inkpop service (the “Service”) to you. This agreement governs both those who create registered
          member accounts through our Service (“Members”) and those who visit or use our Service without creating a
          registered member account (“Visitors”).
        </p>

        <h1 className="mt-2 lg:mt-3 fs-xl fw-extra-bold">Introduction</h1>
        <p className="my-1 fs-18">
          By using the Service, you accept these Terms of Use and the following terms, conditions, and policies,
          including any future amendments (collectively, the “Agreement”):
        </p>
        <ul className="my-p75 fs-18">
          <li className="my-p5">
            <Link to={routes.app.privacyPolicy}>Our Privacy Policy</Link>
          </li>
        </ul>
        <p className="my-p75 fs-18">
          If you register as a Member or make a purchase through the Service on behalf of a business or other
          organization, you represent and warrant that you have the authority to bind that organization and that your
          acceptance of this Agreement will be treated as acceptance by that organization.
        </p>

        <h3 className="mt-1">Changes And Modifications</h3>
        <p className="my-p75">
          We may change or amend these terms from time to time. If we make material changes, we will notify you, either
          through the user interface, in an email notification, or through other reasonable means. Your use of the
          Service after the date such change(s) become effective will constitute your consent to the changed terms. If
          you do not agree to the changes, you must immediately stop using the Service; otherwise, the new terms will
          apply to you. As long as you comply with this Agreement, we grant you a limited, revocable, non-exclusive,
          non-assignable, non-sublicensable right to access and use the Service as it is intended to be accessed and
          used and in accordance with this Agreement and applicable law. We grant you no other rights, implied or
          otherwise.
        </p>

        <h3 className="mt-1p5">Links To Other Sites</h3>
        <p className="my-p75">
          The Service may contain links to independent third-party websites (“Linked Sites”). These Linked Sites are
          provided solely as a convenience to our visitors. Such Linked Sites are not under our common ownership or
          common corporate control, and we are not responsible for and do not necessarily endorse the content or
          practices of such Linked Sites, including any information or materials contained on such Linked Sites. You
          will need to make your own independent judgment regarding your interaction with these Linked Sites.
        </p>

        <h2 className="mt-3">Description of Services</h2>

        <h4 className="mt-1">General Description</h4>
        <p className="my-p75">
          The Service is an online design and sales platform in which custom apparel, telephone cases, and other such
          products (“Products”) may be offered that bear pictures, graphics, and other content uploaded by the Member
          (“User Content”).
        </p>

        <h4 className="mt-1p5">Adults Only</h4>
        <p className="my-p75">
          The Service is intended for adults only. You must be 18 years of age or older – or have the consent of your
          parent or guardian, who must and hereby does consent to these terms – to use the Service or the Inkpop.com
          website. In accordance with Federal Children’s Online Privacy Protection Act of 1998 (“COPPA”), we will never
          knowingly solicit, nor will we accept, personally identifiable information from users of the Service known to
          be under thirteen (13) years of age. Please see our <Link to={routes.app.privacyPolicy}>privacy policy</Link>{' '}
          for additional information or for how to notify us of any concerns you may have in this regard.
        </p>

        <h2 className="mt-3">Conditions of Use</h2>
        <p className="my-p75">As a condition of your use of the Service, you agree to the following:</p>
        <ul className="my-p75">
          <li className="my-p5">
            You will not upload, post, email, transmit, or otherwise make available any content that is in violation of
            copyright law, trademark law, or any other law protecting intellectual property in any jurisdiction, or that
            violates an individual’s right to publicity or privacy.
          </li>
          <li className="my-p5">
            You will not use the Service to harass, threaten, impersonate, or intimidate anyone.
          </li>
          <li className="my-p5">
            You will not upload, post, email, transmit, or otherwise make available any content that is unlawful,
            harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, invasive of
            another’s privacy, hateful, or racially, ethnically, or otherwise objectionable.
          </li>
          <li className="my-p5">
            You will not upload, post, email, transmit or otherwise make available any unsolicited or unauthorized
            advertising, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” “affiliate
            links,” or any other form of solicitation.
          </li>
          <li className="my-p5">You will not transmit any worms or viruses or any code of a destructive nature.</li>
          <li className="my-p5">
            You will not copy, modify, create a derivative work of, reverse engineer, decompile or otherwise attempt to
            extract the source code of the Service or any part thereof.
          </li>
          <li className="my-p5">
            You will not violate any local laws in your jurisdiction (including but not limited to intellectual property
            laws).
          </li>
          <li className="my-p5">
            You will not use the Service for any illegal or unauthorized purpose. If you are an international visitor,
            you agree to comply with all local laws regarding online conduct and acceptable content.
          </li>
          <li className="my-p5">
            You will not use any “deep link”, “page-scrape”, “robot”, “spider” or other automatic device, program,
            algorithm, or methodology (or any similar or equivalent manual process) to access, acquire, copy, or monitor
            any portion of the Service or any User Content, or in any way reproduce or circumvent the navigational
            structure or presentation of the Service or any User Content, to obtain or attempt to obtain any materials,
            documents, or information through any means not purposely made available through the Service. We reserve the
            right to bar any such activity.
          </li>
        </ul>

        <h2 className="mt-3">Your Member Account</h2>
        <p className="my-p75">
          A registered Member account is required to design, share, or purchase Products through the Service; you do not
          need to be a Member of the Service to browse the Inkpop.com website.
        </p>

        <h4 className="fw-bold mt-1">Your Password</h4>
        <p className="my-p75">
          You are solely responsible for maintaining the confidentiality of your account information, including your
          password, and for any and all activities that occur under your account. You agree to immediately notify us of
          any unauthorized use of your account or password, or any other breach of security.
        </p>

        <h2 className="mt-3">Content</h2>
        <p className="mt-p75">
          The Service is an online design and sales platform that lets Members upload User Content. The Service also
          allows Members and Visitors to share such User Content through third-party web services, such as Facebook and
          Twitter.
        </p>
        <p className="my-p5">
          When you upload or otherwise provide User Content to or through the Service, you retain ownership of any
          copyright (and any other rights) you may already hold in your User Content. You also represent and warrant
          that, with respect to all User Content that you upload, transmit, publish, make available, and/or disseminate
          through the Service, (a) you have all the rights and licenses necessary to use, reproduce, publish, display
          publicly, perform publicly, create derivative works based on, and distribute or otherwise exploit such User
          Content in connection with the Service, and to grant us the licenses set forth in this Agreement; (b) the User
          Content will not infringe or otherwise violate the copyright, trademark, or any other right of any third
          party; and (c) you have the consent, release, and/or permission of each identifiable person depicted in your
          User Content (if any) to upload, transmit, publish, and/or disseminate their name and/or likeness through the
          Service and to use the same in connection with your Product(s).
        </p>
        <p className="my-p5">
          By submitting User Content as set forth above, you hereby do and shall grant us – and our successors, assigns,
          entities that are under our common ownership or common corporate control, and third party service providers –
          a worldwide, non-exclusive, perpetual, irrevocable, royalty-free, fully paid, sublicensable, and transferable
          license to use, reproduce, modify, create derivative works based on, distribute, publicly display, and
          publicly perform, the User Content solely for purposes of providing you with the Service and advertising and
          promoting the Service — including, but not limited to, through the Inkpop.com website and through our social
          media channels, such as Facebook — in any medium and by any means currently existing or yet to be devised.
        </p>
        <p className="mb-p75">
          We make User Content available at our sole discretion and may disable access to any User Content at any time
          and for any reason. By using the Service, you acknowledge and agree that we do not guarantee access to and/or
          hosting of User Content that is published through the Service. For example, if User Content violates these
          Terms of Use, access to it may be disabled. We have the right to refuse to complete any order made through the
          Service for any reason, including, but not limited to, our determination, made in our sole discretion, that
          User Content may infringe the intellectual property rights of a third party.
        </p>

        <h2 className="mt-3">Buyer Payments, Returns, Refunds, and Cancellation Policy</h2>

        <h4 className="my-1">Time of payment</h4>
        <p className="my-p75">
          Due to the extensive amount of work required prior to the printing and shipping of your order (e.g., User
          Content preparation), Buyers will be charged at the time their order is placed.
        </p>

        <h4 className="mt-1p5">Time of Shipment</h4>
        <p className="my-p75">
          For all Products, we will print your Product shortly after the completion of your order and ship the Product
          in due course.
        </p>

        <h4 className="mt-1p5">Cancellations, Refunds, & Exchanges</h4>
        <p className="my-p75">
          Because of the customized nature of Products, we do not allow cancellations, refunds, or exchanges. If, upon
          receipt of your finished Product, you feel that your order was incorrect due to an error on our part, please
          email us within thirty (30) days of your delivery date. Claims are handled on a case-by-case basis.
        </p>

        <h2 className="mt-3">DMCA / Intellectual Property</h2>
        <p className="my-p75">
          If you believe that we, or those using our Service, have violated a copyright, trademark right, or any other
          intellectual property right you claim in your work, please contact us pursuant to the Digital Millennium
          Copyright Act (“DMCA”) and our Intellectual Property Policy at copyright@inkpop.com. Please review our DMCA /
          Intellectual Property Policy for more details on how to properly notify us of an intellectual property issue.
        </p>

        <h2 className="mt-3">Our Intellectual Property</h2>
        <p className="mt-p75">
          All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music,
          artwork, content, and computer code (collectively, “Our Content”), including but not limited to the design,
          structure, selection, coordination, expression, “look and feel,” and arrangement of Our Content, contained in
          the Service is owned, controlled, or licensed by us, and is protected by trade dress, copyright, patent, and
          trademark laws, and various other intellectual property and other rights. Your User Content (as defined above)
          is owned by you; your User Content is not Our Content.
        </p>
        <p className="my-p5">
          Except as expressly provided in this Agreement, or otherwise permitted by law, Our Content may not be used,
          copied, reproduced, modified, republished, uploaded, posted, publicly displayed, publicly performed, publicly
          performed by means of a digital audio transmission, encoded, translated, transmitted, or distributed in any
          way to any other computer, server, website, or any other medium for publication or distribution, or for any
          other purpose, without our express prior written consent.
        </p>
        <p className="mb-p75">
          For the avoidance of doubt, and without limiting the forgoing, you retain ownership in any trademarks, logos,
          trade dress or other design elements owned by you which you apply to goods purchased through the Service.
        </p>

        <h2 className="mt-3">Violation of this Agreement - Termination</h2>
        <p className="mt-p75 mb-p5">
          You agree that we may, in our sole discretion and without prior notice to you, terminate your access to the
          Service and/or block your future access to the Service if we determine that you have violated this Agreement
          or other agreements or guidelines which may be associated with your use of the Service. Please note that it is
          our policy to terminate the account of members who repeatedly violate the copyrights, trademark rights, or
          other intellectual property rights of third parties.
        </p>
        <p className="mt-p5 mb-p75">
          If we take any legal action against you as a result of your violation of this Agreement, we will be entitled
          to recover from you, and you agree to pay, all reasonable attorneys’ fees and costs of such action, in
          addition to any other relief granted. You agree that we will not be liable to you or to any third party for
          termination of your access to the Service.
        </p>

        <h2 className="mt-3">Disclaimer of Warranties</h2>
        <p className="mt-p75 mb-p5">
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE, AND ANY
          GOOD OR SERVICE ORDERED THROUGH THE SERVICE, IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. WE AND OUR
          PARENTS, SUBSIDIARIES, DIRECTORS, OFFICERS, EMPLOYEES, STOCKHOLDERS, LICENSORS, AND ANY PARTY UNDER OUR COMMON
          OWNERSHIP OR COMMON CORPORATE CONTROL (“OUR AFFILIATES”) EXPRESSLY DISCLAIM ALL REPRESENTATIONS, WARRANTIES,
          AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES
          OF MERCHANTABILITY, MERCHANTABLE QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE OR ANY
          WARRANTY OR CONDITION ARISING BY USAGE OF TRADE, COURSE OF DEALING, OR COURSE OF PERFORMANCE. WE AND OUR
          AFFILIATES MAKE NO WARRANTY THAT (a) THE SERVICE WILL MEET YOUR REQUIREMENTS; (b) THE SERVICE WILL BE
          UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (c) CONTENT WILL BE HOSTED AND/OR TRANSMITTED WITHOUT
          INTERRUPTION OR CESSATION; (d) ANY PRODUCTS, SITES, INFORMATION, OR OTHER MATERIAL, WHETHER IN TANGIBLE OR
          INTANGIBLE FORM, PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS OR ANY STANDARD
          OF QUALITY; AND (e) ANY DEFECTS IN THE OPERATION OR FUNCTIONALITY OF THE SERVICE OR RELATED SOFTWARE WILL BE
          CORRECTED.
        </p>
        <p className="mt-p5 mb-p75">
          ANY MATERIAL, INFORMATION, OR DATA DOWNLOADED, VIEWED, SHARED, OR OTHERWISE ACCESSED THROUGH THE SERVICE IS
          ACCESSED AT YOUR OWN DISCRETION AND RISK; YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER
          SYSTEM OR LOSS OF DATA THAT RESULT FROM THE DOWNLOADING, VIEWING, SHARING, OR OTHERWISE ACCESSING OF SUCH
          MATERIAL. NO ADVICE, REPRESENTATION, OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU EITHER FROM US
          OR THROUGH THE Inkpop.COM WEBSITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE AGREEMENT.
        </p>

        <h2 className="mt-3">Limitation of Liability</h2>
        <p className="mt-p75 mb-p5">
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT WE AND OUR AFFILIATES WILL NOT BE LIABLE TO YOU FOR ANY DIRECT,
          INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES THAT MAY BE INCURRED BY YOU,
          HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY. THIS INCLUDES, BUT IS NOT LIMITED TO, ANY LOSS OF PROFITS,
          GOODWILL OR BUSINESS REPUTATION; ANY LOSS OF DATA; ANY COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; OR
          ANY OTHER INTANGIBLE LOSSES. THIS ALSO INCLUDES ANY LOSS OR DAMAGES THAT MAY BE INCURRED BY YOU AS A RESULT OF
          (a) ANY CHANGES THAT WE MAY MAKE TO THE SERVICE; (b) ANY PERMANENT OR TEMPORARY CESSATION OF THE SERVICE; (c)
          THE DELETION OR CORRUPTION OF OR FAILURE TO STORE ANY USER CONTENT MAINTAINED THROUGH THE SERVICE; OR (d) YOUR
          FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE. THE LIMITATIONS AND EXCLUSIONS OF LIABILITY ABOVE
          SHALL APPLY IRRESPECTIVE OF THE THEORY OF LIABILITY, INCLUDING CONTRACT (INCLUDING FUNDAMENTAL BREACH),
          WARRANTY, PRODUCT LIABILITY, STRICT LIABILITY, TORT (INCLUDING NEGLIGENCE), OR OTHER THEORY, EVEN IF WE (OR
          OUR AFFILIATES) HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p className="mt-p5 mb-p75">
          YOU SPECIFICALLY ACKNOWLEDGE THAT WE AND OUR AFFILIATES SHALL NOT BE LIABLE FOR USER CONTENT OR THE
          INFRINGING, DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE
          FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
        </p>

        <h2 className="mt-3">Exclusions and Limitations</h2>
        <p className="my-p75">
          SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF CERTAIN WARRANTIES OR CONDITIONS OR THE LIMITATION OR
          EXCLUSION OF LIABILITY FOR CERTAIN DAMAGES. ACCORDINGLY, ONLY THE LIMITATIONS THAT ARE LAWFUL IN YOUR
          JURISDICTION WILL APPLY TO YOU AND, IN SUCH INSTANCES, OUR LIABILITY AND THAT OF OUR AFFILIATES WILL BE
          LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
        </p>

        <h2 className="mt-3">Indemnification</h2>
        <p className="my-p75">
          You will defend, indemnify, and hold us and Our Affiliates harmless from and against any and all liabilities
          and costs (including reasonable attorneys’ fees) we may incur in connection with any claim arising out of your
          breach of this Agreement. We reserve the right, but have no obligation, to assume the exclusive defense and
          control of any matter otherwise subject to indemnification by you.
        </p>

        <h2 className="mt-3">General Terms</h2>

        <h4 className="mt-1">Entire Agreement</h4>
        <p className="my-p75">
          This Agreement is the whole legal agreement between you and us. It governs your use of the Service and
          completely replaces any prior agreements between you and us with respect to the Service. You may also be
          subject to additional terms and conditions that may apply when you use or purchase other services from us, our
          affiliates, or our parents or subsidiary entities.
        </p>

        <h4 className="mt-1p5">Governing Law</h4>
        <p className="my-p75">
          You agree that all matters relating to your access to or use of the Service, including all disputes, will be
          governed by the laws of the State of California without regard to its conflicts of laws provisions. You agree
          to the personal jurisdiction by and venue in Alameda County, California, and waive any objection to such
          jurisdiction or venue.
        </p>

        <h4 className="mt-1p5">Dispute Resolution</h4>
        <p className="mt-p75 mb-p5">
          In the event of any controversy or dispute between you and us arising out of or in connection with your use of
          the Service, we will attempt, promptly and in good faith, to resolve the dispute. If we are unable to resolve
          any such dispute within a reasonable time, not to exceed ninety (90) days, any controversy or claim arising
          out of or relating to this Agreement, or the breach thereof, or your use of the Service shall be settled by
          binding arbitration administered by the American Arbitration Association in accordance with its Commercial
          Arbitration Rules and judgment on the award rendered by the arbitrator(s) may be entered in any court having
          jurisdiction thereof.
        </p>
        <p className="mb-p75 mt-p5">
          The place of arbitration shall be Alameda County, California.. The arbitration shall be governed by the laws
          of the State of California. The arbitrators will have no authority to award punitive or other damages not
          measured by the prevailing party's actual damages, except as may be required by statute. The arbitrator(s)
          shall not award consequential damages in any arbitration initiated under this section. The arbitrator(s) shall
          award to the prevailing party, if any, as determined by the arbitrators, all of their costs and fees. "Costs
          and fees" mean all reasonable pre-award expenses of the arbitration, including the arbitrators' fees,
          administrative fees, travel expenses, out-of-pocket expenses such as copying and telephone, court costs,
          witness fees, and fees. The award of the arbitrators shall be accompanied by a reasoned opinion. Except as may
          be required by law, neither a party nor an arbitrator may disclose the existence, content, or results of any
          arbitration hereunder without the prior written consent of both parties.
        </p>

        <h4 className="mt-1p5">Statute Of Limitations</h4>
        <p className="mt-p75">
          You agree that, regardless of any statute or law to the contrary, any claim under this Agreement must be
          brought within one (1) year after the cause of action arises, or such claim or cause of action is forever
          barred.
        </p>

        <h4 className="mt-1p5">Severability of Terms; Non-waiver of Terms; Assignment</h4>
        <p className="my-p75">
          If any portion(s) of the Agreement is held to be invalid or unenforceable, such provision(s) shall be stricken
          and the remainder of the Agreement enforced as written. If we do not exercise or enforce any legal right or
          remedy including those contained in the Agreement or arising under applicable law, this will not be taken to
          be a formal waiver or relinquishment of our rights. We may assign or delegate all rights and obligations under
          the Agreement, fully or partially.
        </p>

        <h4 className="mt-1p5">Notices</h4>
        <p className="my-p75">
          We may provide you with notices, including those regarding changes to the Agreement, by email, by regular
          mail, or by posting to the Service. Inkpop's mailing address is 211 N Pennsylvania Street, Suite 600,
          Indianapolis, IN. If you have general questions or concerns regarding these Terms of Use, please contact us in
          writing.
        </p>

        <h4 className="mt-1p5">#QuaranTeeChallenge</h4>
        <p className="my-p75">Help WHO fight COVID-19</p>
        <h5 className="mt-1">How to participate:</h5>
        <ol className="my-p75">
          <li>
            Follow{' '}
            <a href={InkpConfig.contact.instagramPageUrl} target="_blank">
              @inkpopinc
            </a>
          </li>
          <li>Share a story or post of your quarantine T-shirt outfit.</li>
          <li>
            Tag{' '}
            <a href={InkpConfig.contact.instagramPageUrl} target="_blank">
              @inkpopinc
            </a>{' '}
            and include the{' '}
            <a href="https://www.instagram.com/explore/tags/QuaranTeeChallenge/" target="_blank">
              #QuaranTeeChallenge
            </a>{' '}
            hashtag on Instagram.
          </li>
          <li>
            Each participant will get a 25% off coupon to purchase at{' '}
            <a href="https://inkpop.com/" target="_blank">
              inkpop.com
            </a>
          </li>
          <li>
            <strong>5%</strong> of all orders placed with this coupon will be donated to{' '}
            <a href="https://www.who.int/" target="_blank">
              www.who.int
            </a>
          </li>
        </ol>
        <h5 className="mt-1">Donations:</h5>
        <p className="my-p75">
          All achieved donations will be donated directly to COVID-19 Solidarity Response Fund for WHO (
          <a href="https://covid19responsefund.org/" target="_blank">
            https://covid19responsefund.org/
          </a>
          ).
        </p>
        <h5 className="mt-1">Coupon:</h5>
        <p className="my-p75">25% discount coupon not cumulative with other coupons. Valid until 5/31/2020.</p>
        <h5 className="mt-1">Shipping:</h5>
        <p className="my-p75">
          Shipping orders available only to US. This challenge is in no way endorsed or sponsored by Instagram or
          Facebook.
        </p>

        <div className="my-4 lg:my-7">
          <CantFindProduct />
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Inkpop - Terms of Service</title>
        </Helmet>
        <React.Fragment>
          <div className="w-full bgc-gray-50">
            <div className="w-full flex flex-wrap lg:w-container lg:mx-auto lg:py-0">
              <div className="d-ib w-1/2 ta-left my-1p5 px-1 flex items-center fs-xs">
                <Link to={'/'} className={''}>
                  <span className="d-ib color-blue">Home</span>
                </Link>
                <div className="d-ib">
                  <i className={'mdi mdi-chevron-right p-0 color-gray-500 fw-bold'} />
                </div>
                <div className="d-ib">
                  <span className="d-ib color-blue color-gray-500 fw-bold">Help</span>
                </div>
              </div>
              <div className="w-full ta-left px-1">
                <h3 className="color-navy fs-3xl fw-extra-bold pb-1p5 mt-1p5">Terms of Service</h3>
                <h4 className="color-gray-500 pb-3">Effective as of July 15th 2019</h4>
              </div>
            </div>
          </div>
          <div className="w-full bgc-white">
            <div className="w-full lg:w-container lg:mx-auto lg:py-0">
              <div className="w-full lg:w-7/10 lg:mx-auto">{this.renderContent()}</div>
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}
