import React from 'react';
import config from 'inkp-config';

interface Props { }

const CantFindProduct: React.FunctionComponent<Props> = () => {
  return (
    <div className="w-full flex br-p5 overflow-hidden bc-blue bw-1 bgc-blue-50 ta-left">
      <div className="w-full p-1p5 md:d-ib md:w-5/12">
        <div className="mb-2p25">
          <p className="fs-xl color-blue fw-extra-bold">
            <span className="d-b">Can't find what you're</span>
            <span className="d-b">looking for?</span>
          </p>
        </div>
        <div className="mt-2p25 mb-2">
          <p className="m-0 color-primary fw-extra-bold fs-lg">
            <span className="d-b">Contact us.</span>
            <span className="d-b">We are here to help.</span>
          </p>
        </div>
        <div className="mt-1">
          <p className="m-0 mb-p5">
            <a href={`mailto: ${config.contact.email}`}>
              <span className="color-blue fs-med fw-bold underline">{config.contact.email}</span>
            </a>
          </p>
          <p className="mt-p5 mb-p5">
            <a href={`tel: ${config.contact.phone}`}>
              <span className="color-blue fs-med fw-bold underline">{config.contact.phone}</span>
            </a>
          </p>
        </div>
      </div>
      <div className="d-n md:d-ib md:w-7/12">
        <img
          className="w-full h-full"
          src="https://inkp-production.32pt.com/public/app/ontas.jpg"
          alt="Can't Find What You Need?"
        />
      </div>
    </div>
  );
};

export default CantFindProduct;
